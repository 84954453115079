import React from 'react';
import styled from 'styled-components';
import { Icon } from 'antd';
import { TypeMenuItem } from '../PageTemplate';
import { colors } from '@digi-tim-19/theme';
interface ItemMenuConfig {
  item: TypeMenuItem;
  menuIsOpen?: boolean;
  toggleMenu?: Function;
  handleItemMenuClick: Function;
}

const Item = styled.li`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  justify-content: center;
  color: white;
  width: 100%;
  font-weight: 700;
  font-family: TimBold;
  margin-bottom: 14px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;

  &:hover {
    background-color: ${colors.red};
    border-radius: 12px;
  }

  & > div {
    display: flex;
    align-items: center;

    & span {
      padding-left: 8px;
      font-size: 14px;
    }
  }
`;

export const ItemMenu = ({
  item,
  handleItemMenuClick,
}: ItemMenuConfig) => {
  return (
    <Item>
      <Container
        onClick={() => handleItemMenuClick(item)}
      >
        <div style={{ width: '-webkit-fill-available' }}>
          <Icon type={item.typeIcon} style={{ fontSize: '24px' }} />
          <span>{item.text}</span>
        </div>
        {item.children && item.children.length > 0 && (
          <span>
            <Icon type="right" style={{ fontSize: '11px' }} />
          </span>
        )}
      </Container>
    </Item>
  );
};
