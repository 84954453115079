"use strict";

var _slicedToArray = require("/home/jenkins/workspace/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

var index_1 = require("../index");

var build_1 = require("@digi-tim-19/utils/build");

exports.SearchUser = function (props) {
  var _a, _b, _c;

  var _React$useState = React.useState([]),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      options = _React$useState2[0],
      setOptions = _React$useState2[1];

  var actions = index_1.useMaterialActions();

  var _React$useState3 = React.useState(),
      _React$useState4 = _slicedToArray(_React$useState3, 2),
      idsAux = _React$useState4[0],
      setIdsAux = _React$useState4[1];

  var formApi = index_1.useForm();
  var firstLoading = React.useRef(true);

  var _React$useState5 = React.useState(true),
      _React$useState6 = _slicedToArray(_React$useState5, 2),
      isLoading = _React$useState6[0],
      setIsLoading = _React$useState6[1];

  var initialValue = React.useMemo(function () {
    return formApi.getState().values[props.name];
  }, []);
  React.useEffect(function () {
    if (!initialValue) {
      setIsLoading(false);
      return;
    }

    if (initialValue && initialValue.length > 0) {
      var isEmail = build_1.isEmailValid(initialValue[0]);

      if (isEmail) {
        actions.searchUserByEmails({
          emails: initialValue
        }).then(function (_ref) {
          var result = _ref.result;
          return callbackFindUserIntialData(result.filter(Boolean), true);
        });
      } else {
        actions.searchUserByIds({
          _ids: initialValue
        }).then(function (_ref2) {
          var result = _ref2.result;
          return callbackFindUserIntialData(result.filter(Boolean), false);
        });
      }
    } else setIsLoading(false);

    function callbackFindUserIntialData(result, isEmail) {
      setOptions((result || []).map(function (el) {
        return {
          _id: el._id,
          name: el.name,
          email: el.email
        };
      }));
      if (isEmail) setIdsAux(result.map(function (x) {
        return x._id;
      }));
      setIsLoading(false);
    }
  }, []);
  return React.createElement(index_1.SearchUserBase, {
    required: ((_a = props) === null || _a === void 0 ? void 0 : _a.required) || false,
    name: props.name,
    dataSource: options,
    multiple: true,
    label: props.placeholder || 'BUSCAR MATRÍCULA',
    isLoading: isLoading,
    returnProp: (_c = (_b = props) === null || _b === void 0 ? void 0 : _b.extraProps) === null || _c === void 0 ? void 0 : _c.returnProp,
    idsAux: idsAux,
    onSearch: function onSearch(value) {
      firstLoading.current = false;
      actions.searchUser({
        search: value
      }).then(function (_ref3) {
        var result = _ref3.result;
        setOptions((result || []).map(function (el) {
          return {
            _id: el._id,
            name: el.name,
            email: el.email
          };
        }));
      });
    }
  });
};

exports.default = exports.SearchUser;