"use strict";

var _toConsumableArray = require("/home/jenkins/workspace/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

var _slicedToArray = require("/home/jenkins/workspace/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
/* eslint-disable no-unused-expressions */

var react_1 = __importStar(require("react"));

var antd_1 = require("antd");

var __1 = require("../");

var ModalFooter_1 = require("../Modal/ModalFooter");

var MaterialActionsProvider_1 = require("../DataProviders/MaterialActionsProvider");

var Tree_1 = require("./Tree");

var stylesSelecionarPublico_1 = require("./stylesSelecionarPublico");

var ProviderSelecionarPublico_1 = require("./ProviderSelecionarPublico");

var lodash_1 = require("lodash");

exports.SelecionarPublicoSemFormer = function (props) {
  var _a, _b;

  var _props$platform = props.platform,
      platform = _props$platform === void 0 ? 'consumer' : _props$platform,
      _props$required = props.required,
      required = _props$required === void 0 ? false : _props$required;
  var actions = MaterialActionsProvider_1.useMaterialActions();
  var selecionarPublico = ProviderSelecionarPublico_1.useSelecionarPublico();

  var _react_1$useState = react_1.useState(false),
      _react_1$useState2 = _slicedToArray(_react_1$useState, 2),
      visible = _react_1$useState2[0],
      setVisible = _react_1$useState2[1];

  var _react_1$useState3 = react_1.useState(1),
      _react_1$useState4 = _slicedToArray(_react_1$useState3, 2),
      step = _react_1$useState4[0],
      setStep = _react_1$useState4[1];

  var _react_1$useState5 = react_1.useState(),
      _react_1$useState6 = _slicedToArray(_react_1$useState5, 2),
      regions = _react_1$useState6[0],
      _setRegions = _react_1$useState6[1];

  var _react_1$useState7 = react_1.useState(),
      _react_1$useState8 = _slicedToArray(_react_1$useState7, 2),
      channels = _react_1$useState8[0],
      _setChannels = _react_1$useState8[1];

  function setRegions(value) {
    if (props.filterRegions) {
      value = props.filterRegions(value);
    }

    _setRegions(value);
  }

  function setChannels(value) {
    if (props.filterChannels) {
      value = props.filterChannels(value);
    }

    _setChannels(value);
  }

  var defaultRegions = react_1.useMemo(function () {
    var _a, _b;

    var regions = (_b = (_a = props) === null || _a === void 0 ? void 0 : _a.initialValues) === null || _b === void 0 ? void 0 : _b.availableAtRegions;
    if (!regions || !props.filterRegions) return;
    var tree = regions.map(function (el) {
      return {
        key: el,
        value: el
      };
    });
    return props.filterRegions(tree).map(function (el) {
      return el.key;
    });
  }, []);
  var defaultChannels = react_1.useMemo(function () {
    var _a, _b, _c, _d, _e;

    if (!((_a = props) === null || _a === void 0 ? void 0 : _a.initialValues) || !props.filterChannels) return;
    var tree = [].concat(_toConsumableArray((_c = (_b = props) === null || _b === void 0 ? void 0 : _b.initialValues) === null || _c === void 0 ? void 0 : _c.availableAtRoleGroups), _toConsumableArray((_e = (_d = props) === null || _d === void 0 ? void 0 : _d.initialValues) === null || _e === void 0 ? void 0 : _e.availableAtChannels)).map(function (el) {
      return {
        key: el,
        value: el
      };
    });
    return props.filterChannels(tree).map(function (el) {
      return el.key;
    });
  }, []);

  function setAllChannels(channels) {
    var allChannels = [];
    channels.map(function (channel) {
      var _a;

      if (!channel.parentId) {
        allChannels.push(channel._id);
      }

      (_a = channel.roleGroups) === null || _a === void 0 ? void 0 : _a.map(function (roleGroup) {
        if (roleGroup) {
          allChannels.push(roleGroup._id);
        }
      });
    });
    selecionarPublico.setState('allChannels', lodash_1.uniq(allChannels));
  }

  react_1.useEffect(function () {
    var _a;

    if (props.selectedChannels && ((_a = props.selectedChannels) === null || _a === void 0 ? void 0 : _a.length) > 0) {
      //criado inicialmente para ser usado nos banners de consumer, mas é para setar inicialmente os canais no selecionar publico
      selecionarPublico.setState('channels', props.selectedChannels);
    } else {
      selecionarPublico.setState('channels', defaultChannels || []);
    }

    selecionarPublico.setState('regions', defaultRegions || []);
  }, [(_a = props) === null || _a === void 0 ? void 0 : _a.initialValues]);
  react_1.useEffect(function () {
    actions.fetchRegions({
      appendToFragment: "\n      items {\n        name\n        _id\n        items {\n          _id\n          DDD\n        }\n      }\n    "
    }).then(function (ctx) {
      var resultRegions = lodash_1.sortBy(ctx.result, 'name');
      setRegions((resultRegions || []).filter(function (value) {
        return removeHQRegion(value, platform);
      }).map(function (item) {
        var _a, _b, _c, _d, _e;

        return {
          title: platform === 'corp' && ((_a = item) === null || _a === void 0 ? void 0 : _a.name) === 'HQ' ? 'BR' : (_b = item) === null || _b === void 0 ? void 0 : _b.name,
          key: ((_c = item) === null || _c === void 0 ? void 0 : _c.name) || '',
          value: ((_d = item) === null || _d === void 0 ? void 0 : _d.name) || '',
          children: (((_e = item) === null || _e === void 0 ? void 0 : _e.items) || []).map(function (city) {
            var _a, _b, _c, _d, _e;

            return {
              title: platform === 'corp' && ((_a = item) === null || _a === void 0 ? void 0 : _a.name) === 'HQ' ? 'BR' : (_b = city) === null || _b === void 0 ? void 0 : _b.name,
              key: ((_c = city) === null || _c === void 0 ? void 0 : _c._id) || '',
              value: ((_d = item) === null || _d === void 0 ? void 0 : _d._id) || '',
              children: (((_e = city) === null || _e === void 0 ? void 0 : _e.items) || []).map(function (ddd) {
                var _a, _b, _c;

                return {
                  title: ((_a = ddd) === null || _a === void 0 ? void 0 : _a.DDD) || '',
                  key: ((_b = ddd) === null || _b === void 0 ? void 0 : _b._id) || '',
                  value: ((_c = ddd) === null || _c === void 0 ? void 0 : _c._id) || ''
                };
              })
            };
          })
        };
      }));
    });
    actions.fetchChannels({
      appendToFragment: "\n      roleGroups {\n        name\n        _id\n      }\n      subChannels {\n        _id\n        name\n        roleGroups {\n          _id\n          name\n        }\n      }\n    "
    }).then(function (ctx) {
      var resultChannels = lodash_1.sortBy(ctx.result, 'name');
      setAllChannels(ctx.result || []);
      setChannels((resultChannels || []).filter(function (item) {
        return item.parentId === null;
      }).map(function (item) {
        var _a, _b, _c, _d, _e;

        var roleGroupsSubChannels = ((_b = (_a = item) === null || _a === void 0 ? void 0 : _a.roleGroups) === null || _b === void 0 ? void 0 : _b.concat(item.subChannels)) || [];
        var resultRoleGroups = lodash_1.sortBy(roleGroupsSubChannels, 'name');
        return {
          title: ((_c = item) === null || _c === void 0 ? void 0 : _c.name) || '',
          key: ((_d = item) === null || _d === void 0 ? void 0 : _d._id) || '',
          value: ((_e = item) === null || _e === void 0 ? void 0 : _e._id) || '',
          //@ts-ignore
          children: resultRoleGroups.map(function (group) {
            var _a, _b, _c, _d, _e, _f;

            return {
              title: ((_a = group) === null || _a === void 0 ? void 0 : _a.name) || '',
              key: group.roleGroups ? "sub-".concat((_b = group) === null || _b === void 0 ? void 0 : _b._id) : ((_c = group) === null || _c === void 0 ? void 0 : _c._id) || '',
              value: ((_d = group) === null || _d === void 0 ? void 0 : _d._id) || '',
              children: ((_e = group) === null || _e === void 0 ? void 0 : _e.roleGroups) && (((_f = group) === null || _f === void 0 ? void 0 : _f.roleGroups) || []).map(function (roleGroups) {
                var _a, _b, _c;

                return {
                  title: ((_a = roleGroups) === null || _a === void 0 ? void 0 : _a.name) || '',
                  key: ((_b = roleGroups) === null || _b === void 0 ? void 0 : _b._id) || '',
                  value: ((_c = roleGroups) === null || _c === void 0 ? void 0 : _c._id) || ''
                };
              })
            };
          })
        };
      }));
    });
  }, []);

  var formatedData = function formatedData(entity) {
    return [{
      title: 'Selecionar Todos',
      key: 'Selecionar Todos',
      children: entity
    }];
  };

  var onNext = function onNext() {
    return setStep(function (state) {
      return state + 1;
    });
  };

  var onCancel = function onCancel() {
    if (platform === 'corp') {
      antd_1.message.error('Nenhum público foi selecionado');
    }

    setVisible(false);
    setStep(1);
    selecionarPublico.reset(selecionarPublico.state);
  };

  var onBack = function onBack() {
    if (step === 1) return;
    setStep(function (current) {
      return current - 1;
    });
  };

  var selectedChannels = react_1.useMemo(function () {
    var _a, _b;

    if ((_a = defaultChannels) === null || _a === void 0 ? void 0 : _a.length) return defaultChannels;
    if ((_b = selecionarPublico.state.channels) === null || _b === void 0 ? void 0 : _b.length) return selecionarPublico.state.channels;
    return [];
  }, [(_b = selecionarPublico.state.channels) === null || _b === void 0 ? void 0 : _b.length]);
  return react_1.default.createElement(stylesSelecionarPublico_1.Container, null, react_1.default.createElement(__1.Button, {
    style: props.styleButton,
    onClick: function onClick() {
      return setVisible(true);
    }
  }, "Selecionar P\xFAblico"), react_1.default.createElement(stylesSelecionarPublico_1.Modal, {
    visible: visible,
    onCancel: onCancel,
    footer: [react_1.default.createElement(ModalFooter_1.ModalFooter, {
      key: "1",
      step: step,
      onBack: onBack,
      onNext: onNext,
      onDone: function onDone() {
        if (platform === 'corp') {
          if (selecionarPublico.state.channels.length === 0) {
            antd_1.message.error('Selecione o canal e perfil');
            return;
          }

          if (selecionarPublico.state.regions.length === 0) {
            antd_1.message.error('Selecione a região');
            return;
          }
        } else if (platform === 'consumer' && required) {
          if (selecionarPublico.state.channels.length === 0 && selecionarPublico.state.regions.length === 0 || selecionarPublico.state.channels.length > 0 && selecionarPublico.state.regions.length === 0) {
            antd_1.message.error('Selecione a região');
            return;
          }
        }

        setVisible(false);
      }
    })]
  }, step === 1 && react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(stylesSelecionarPublico_1.Title, null, "Selecione o Canal e Perfil"), react_1.default.createElement(Tree_1.Tree, {
    name: "channels",
    data: formatedData(channels),
    initialValues: selectedChannels,
    onChange: props.onChange
  })), step === 2 && react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(stylesSelecionarPublico_1.Title, null, "Selecione a regi\xE3o"), Boolean(required && platform === 'consumer' && (selecionarPublico.state.channels.length === 0 && selecionarPublico.state.regions.length === 0 || selecionarPublico.state.channels.length > 0 && selecionarPublico.state.regions.length === 0)) && react_1.default.createElement(stylesSelecionarPublico_1.SubTitleError, null, "*Selecione pelo menos uma regi\xE3o"), react_1.default.createElement(Tree_1.Tree, {
    name: "regions",
    data: formatedData(regions),
    initialValues: defaultRegions || selecionarPublico.state.regions,
    onChange: props.onChange
  }), ' ')));
};

function removeHQRegion(item, platform) {
  if (platform === 'consumer') {
    return item._id !== 'HQ';
  }

  return item._id !== 'THQ';
}