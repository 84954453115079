"use strict";

var _slicedToArray = require("/home/jenkins/workspace/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var React = __importStar(require("react"));

exports.useToggle = function () {
  var initial = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

  var _React$useState = React.useState(initial),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      value = _React$useState2[0],
      setValue = _React$useState2[1];

  return [value, function (newState) {
    setValue(typeof newState === 'boolean' ? newState : !value);
  }];
};