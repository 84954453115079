"use strict";

var _classCallCheck = require("/home/jenkins/workspace/tim-corp/packages/site-corp/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});

var isBrowser_1 = require("./isBrowser");

var ServerStorage = {
  getItem: function getItem() {
    return null;
  },
  removeItem: function removeItem() {},
  setItem: function setItem() {}
};

var CacheHandler = function CacheHandler(config) {
  var _this = this;

  _classCallCheck(this, CacheHandler);

  this.listeners = [];

  this.setStorage = function () {
    var _this$config = _this.config,
        storage = _this$config.storage,
        memory = _this$config.memory;

    if (isBrowser_1.isBrowser()) {
      if (memory) {
        _this.storage = new BrowserMemoryStorage();
      } else {
        if (!storage) {
          _this.storage = localStorage;
        } else {
          _this.storage = storage;
        }
      }
    } else {
      _this.storage = ServerStorage;
    }

    if (typeof _this.storage === 'undefined') {
      throw new Error('storage is empty');
    }
  };

  this.setItem = function (key, value) {
    var cached = JSON.parse(_this.storage.getItem(_this.config.storageKey) || '{}');
    cached[key] = value;

    _this.storage.setItem(_this.config.storageKey, exports.minifyJSON(JSON.stringify(cached)));

    _this.listeners.forEach(function (ln) {
      return ln(key, value);
    });
  };

  this.getItem = function (key) {
    var cached = JSON.parse(_this.storage.getItem(_this.config.storageKey) || '{}');
    return cached[key];
  };

  this.clear = function () {
    _this.storage.removeItem(_this.config.storageKey);
  };

  this.listen = function (fn) {
    _this.listeners.push(fn);
  };

  this.unlisten = function (fn) {
    _this.listeners = _this.listeners.filter(function (l) {
      return l !== fn;
    });
  };

  this.emit = function (key, value) {
    _this.listeners.forEach(function (ln) {
      return ln(key, value);
    });
  };

  this.values = function () {
    return JSON.parse(_this.storage.getItem(_this.config.storageKey) || '{}');
  };

  if (!config || !config.storageKey) {
    throw new Error('invalid storageKey');
  }

  this.config = config;
  this.setStorage();
};

exports.CacheHandler = CacheHandler;

var BrowserMemoryStorage = function BrowserMemoryStorage() {
  var _this2 = this;

  _classCallCheck(this, BrowserMemoryStorage);

  this.store = {};

  this.getItem = function (key) {
    return _this2.memory[_this2.memoryIndex][key];
  };

  this.removeItem = function (key) {
    delete _this2.memory[_this2.memoryIndex][key];
  };

  this.setItem = function (key, value) {
    if (typeof key !== 'string') {
      throw new Error('invalid key');
    }

    if (typeof value !== 'string') {
      throw new Error('invalid value');
    }

    _this2.memory[_this2.memoryIndex][key] = value;
  };

  this.memoryIndex = randomKey();
  this.memory = window;
  this.memory[this.memoryIndex] = {};
  this.store = this.memory[this.memoryIndex];
};

function randomKey() {
  return "memoryCacheEntry__".concat(Math.random() * 1000).concat(Math.random() * 1000).replace(/\./gim, '');
}

exports.minifyJSON = function (json) {
  return json.replace(/\n|\\n/gim, ' ').replace(/  /gim, '');
};